import styled from 'styled-components';
import React from 'react';
import StyledH1 from '../StyledH1';

const Container = styled.div`
    display: flex;
    align-items: center;
    margin-left: 50px;
`;

const ImageContainer = styled.div`
    height: auto;
    margin-right: 20px;
    background-color: rgba(0, 0, 0, 0.2);
    padding: 10px;
`;

const Image = styled.img`
    position: relative;
    left: -100px;
    width: 100px;
    height: auto;
    opacity: 0.7;
`;

const TextContainer = styled.div`
    display: flex;
    flex-direction: column;
    margin-left: -120px;
    flex-grow: 1;
`;

const Slogan = styled.p`
    color: #ffffff;
    margin: 0;
    font-size: 1.2em;
    text-shadow: 0 0 20px rgba(183, 105, 199, 0.9);
`;

const Brand = () => {
    return (
        <Container>
            <ImageContainer>
                <Image src="/logo.svg" alt="Your Image Alt Text"/>
            </ImageContainer>
            <TextContainer>
                <StyledH1 text={"IDECENTRALIZE"}/>
                <Slogan>The limit is our imagination</Slogan>
            </TextContainer>
        </Container>
    );
};

export default Brand;
