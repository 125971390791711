import React, {useEffect} from 'react';
import styled from 'styled-components';
import {Container} from 'react-bootstrap';
import Brand from './Brand';
import Introduction from "./Introduction";

const FlexContainer = styled.div`
    position: relative;
    top: 44px;
    display: block;
    min-height: calc(100vh - 44px);
    overflow: auto;
`;

const UpperContainer = styled(Container)`
    background-color: #000000;
    padding: 20px;
    position: relative;
    top: 0;
    left: 0;
    right: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;
    text-align: left;
`;

const MiddleContainer = styled.div`
    position: relative;
    left: 0;
    right: 0;
    height: calc(100vh - 44px);
    flex-grow: 1;
    display: flex;
    background-image: url('/fibe.jpeg');
    background-size: cover;
    background-position: center;
    align-items: start;
    color: #ffffff;
`;

const VerticalCenteredImage = ({imageUrl, altText}) => {

    useEffect(() => {
    }, []);

    return (
        <FlexContainer id="home">
            <UpperContainer>
                <Brand/>
            </UpperContainer>
            <MiddleContainer>
                <Introduction/>
            </MiddleContainer>
        </FlexContainer>
    );
};

export default VerticalCenteredImage;
