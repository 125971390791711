import React from 'react';
import styled from 'styled-components';
import {FaCoins, FaExchangeAlt, FaWallet} from 'react-icons/fa';

const FlexContainer = styled.div`
    text-align: center;
    position: relative;
    top: 44px;
    padding: 60px 20px;
    margin: auto;
    background-color: rgba(0, 0, 0, 0);
    height: calc(100vh - 44px);
    overflow: auto;
`;

const FeaturesContainer = styled.div`
    display: flex;
    margin: auto;
    flex-wrap: wrap;
    justify-content: center;
    gap: 40px;
    max-width: 1200px;
`;

const FeatureCard = styled.div`
    background-color: #1a1a1a;
    padding: 40px;
    border-radius: 12px;
    box-shadow: 0 8px 20px rgba(0, 0, 0, 0.1);
    flex: 1 1 300px;
    max-width: 350px;
    text-align: center;
    transition: transform 0.3s, box-shadow 0.3s;
    color: #f0f0f0;
    &:hover {
        transform: translateY(-10px);
        box-shadow: 0 12px 30px rgba(0, 0, 0, 0.15);
    }
`;

const IconWrapper = styled.div`
    font-size: 3rem;
    color: #8f1f8f;
    margin-bottom: 20px;
`;

const FeatureTitle = styled.h3`
    font-size: 1.75rem;
    color: #f0f0f0;
    margin-bottom: 15px;
`;

const FeatureDescription = styled.p`
    font-size: 1rem;
    color: #f0f0f0;
    line-height: 1.6;
`;

const ComingSoonNote = styled.div`

    padding: 20px;
    background-color: #1a1a1a;
    border: 1px solid #ffeeba;
    border-radius: 8px;
    text-align: center;
    max-width: 800px;
    width: 100%;
    margin: 60px auto auto;
`;

const ComingSoonText = styled.p`
    font-size: 1rem;
    color: #856404;
    margin: 0;
`;

const Features = () => {
    return (
        <FlexContainer id="features">
            <FeaturesContainer>
                <FeatureCard>
                    <IconWrapper>
                        <FaCoins/>
                    </IconWrapper>
                    <FeatureTitle>Create Your Own Token</FeatureTitle>
                    <FeatureDescription>
                        Whether it's a reward to incentivize your clients, a service token, or even your own
                        decentralized currency, our platform provides the tools to create and manage your custom tokens
                        effortlessly.
                    </FeatureDescription>
                </FeatureCard>

                <FeatureCard>
                    <IconWrapper>
                        <FaExchangeAlt/>
                    </IconWrapper>
                    <FeatureTitle>Token Exchange Listing</FeatureTitle>
                    <FeatureDescription>
                        Gain exposure by listing your tokens on major exchanges through our streamlined listing process,
                        connecting your digital assets to a broader audience seamlessly.
                    </FeatureDescription>
                </FeatureCard>

                <FeatureCard>
                    <IconWrapper>
                        <FaWallet/>
                    </IconWrapper>
                    <FeatureTitle>Purchase Cryptocurrency</FeatureTitle>
                    <FeatureDescription>
                        Securely purchase a variety of cryptocurrencies through our decentralized platform, ensuring
                        accessibility from anywhere with robust censorship resistance and full control over your funds.
                    </FeatureDescription>
                </FeatureCard>
            </FeaturesContainer>

            <ComingSoonNote>
                <ComingSoonText>
                    🚀 These exciting features are coming soon! Please note that some functionalities may require
                    licensing and could take longer to implement.
                </ComingSoonText>
            </ComingSoonNote>
        </FlexContainer>
    );
};

export default Features;
