import React from 'react';
import styled from 'styled-components';

// Styled Components for layout and styling
const Container = styled.div`
    position: relative;
    top: 44px;
    height: calc(100vh - 44px);
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
    font-family: 'Arial', sans-serif;
    color: #f0f0f0; // Light text color for contrast on dark background
    background-color: #1a1a1a; // Dark background
    border-radius: 12px;
    overflow: auto;
`;

const Title = styled.h1`
    color: #8f1f8f;
    text-align: center;
    margin-bottom: 20px;
`;

const Subtitle = styled.h2`
    color: #d0d0d0;
    font-size: 1.5em;
    margin-bottom: 15px;
`;

const Paragraph = styled.p`
    line-height: 1.6;
    margin-bottom: 15px;
`;

const ServiceList = styled.ul`
    list-style-type: none;
    padding: 0;
`;

const ServiceItem = styled.li`
    margin-bottom: 10px;
    &:before {
        content: '• ';
        color: #8f1f8f;
    }
`;


const ServiceDescription = () => {
    return (
        <Container id="services">
            <Title>Tailored Blockchain Solutions</Title>
            <Subtitle>Your Vision, Our Expertise</Subtitle>
            <Paragraph>
                At [Your Company Name], we specialize in crafting bespoke blockchain solutions that align perfectly with your unique vision. Whether you're an entrepreneur, an artist, a gaming enthusiast, or a traditional business looking to innovate, we offer services designed to elevate your projects.
            </Paragraph>

            <Subtitle>Our Services Include:</Subtitle>
            <ServiceList>
                <ServiceItem>Custom Blockchain Development: From decentralized applications (dApps) to robust, scalable cloud platforms, we build from the ground up to meet your specific needs.</ServiceItem>
                <ServiceItem>Blockchain Integration: We seamlessly integrate blockchain technology into your existing products, enhancing security, functionality, and user engagement.</ServiceItem>
                <ServiceItem>
                    Industry-Specific Solutions:
                    <ul>
                        <li>Finance & DeFi: Secure, transparent, and efficient solutions for financial products.</li>
                        <li>Gaming: Create immersive, fair, and player-owned economies with blockchain.</li>
                        <li>Art & Collectibles: Authenticate and monetize art through NFTs, ensuring provenance and ownership.</li>
                        <li>Business Operations: Streamline supply chains, improve data integrity, and automate processes with smart contracts.</li>
                    </ul>
                </ServiceItem>
                <ServiceItem>Consultation & Strategy: We provide expert advice on how blockchain can revolutionize your industry, offering strategic insights tailored to your business goals.</ServiceItem>
            </ServiceList>

            <Subtitle>Why Choose Us?</Subtitle>
            <Paragraph>
                - <strong>Transparency:</strong> We believe in open communication and clear project milestones.<br/>
                - <strong>Reliability:</strong> High standards in development ensure your solution is not just innovative but also dependable.<br/>
                - <strong>Innovation:</strong> Leveraging our diverse engineering background, we bring aerospace precision and blockchain's transformative power to your project.
            </Paragraph>

        </Container>
    );
};

export default ServiceDescription;
